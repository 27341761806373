<template>
  <div>
    <LayoutManager v-if="isAuthenticated">
      <div class="h-100 lxp-badge" id="qa-certificate-view">
        <AwardWrapper />
      </div>
    </LayoutManager>
    <LandingPageLayout v-else>
      <div class="h-100">
        <div class="h-100 lxp-badge awards-public" id="qa-certificate-view">
          <AwardWrapper />
        </div>
        <stay-tuned />
      </div>
    </LandingPageLayout>
  </div>
</template>
<script>
import LayoutManager from "@/components/Layout/LayoutManager";
import LandingPageLayout from "@/components/Layout/LandingPageLayout.vue";
import AwardWrapper from "./AwardWrapper.vue";
import StayTuned from "@/components/Landing/StayTuned.vue";
export default {
  name: "CertificateView",
  components: {
    LayoutManager,
    LandingPageLayout,
    AwardWrapper,
    StayTuned
  },
  computed: {
    isAuthenticated() {
      return this.$keycloak.authenticated;
    }
  },
  created() {
    this.$emit("update:layout", "main");
    if (this.$route.query.lang) {
      if (this.allLanguage?.find(l => l.id === this.$route.query.lang)) {
        this.language = this.$route.query.lang;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.lxp-badge {
  padding-top: 18px;
  &.awards-public {
    padding: 0 10%;
  }
}
</style>
